import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login:false,
    token:'',
    name : '',
    phone : '',
    theme: 'light'
  },
  plugins: [createPersistedState()],
  mutations: {
    ChgTheme(state, value){
      state.theme = value;
    },
    SETLOGIN(state, mem_info) {
      
      state.login = true;
      state.token = mem_info.token;
      state.name = mem_info.name;
      state.phone = mem_info.phone;

    },
    SETNAME(state, name) {
      state.name = name;

    },
    SETLOGOUT(state) {
      state.token = '';
      state.name = '';
      state.phone = '';
      state.login = false;
    },
  },
  actions: {
    SETLOGIN({commit}, {mem_info}) {
      commit('SETLOGIN', mem_info);
    },
    SETNAME({commit}, name) {
      commit('SETNAME', name);
    },
    SETLOGOUT ({commit}) {
      commit('SETLOGOUT');
    },
  }
})
