<template>
  <div v-show="pp">
    <div class="basic_pp bottom_up" :class="{ off: off }">
      <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
      <div class="content bg-body overflow-hidden p-0">
        <div class="body overflow-auto" ref="basic_pp_body">
          <div class="search_detail">
            <div
              class="black_bg black_gradient_bg bg-img h-px-250"
              :style="`background-image: url(${item.image_path})`"
              style="height: 250px"
            >
              <div class="text-white">
                <div class="flex-between-center position-relative zindex-1">
                  <div class="py-2 px-3 fs-5" @click="Bookmark()">
                    <i
                      class="fa-bookmark align-self-start"
                      :class="{
                        'fas text-danger': item.bookmark,
                        fal: !item.bookmark,
                      }"
                    ></i>
                  </div>
                  <div class="p-3" @click="pp_off()">
                    <i class="fal fa-times"></i>
                  </div>
                </div>
                <div
                  class="p-4 txt_box zindex-1 text-start w-100 position-absolute bottom-0"
                >
                  <div class="badges mb-2">
                    <ul class="small">
                      <li
                        class="badge rounded-1 me-1 fw-light text-white"
                        :style="`background-color: ${tag.bg}`"
                        v-for="(tag, tab_index) in item.tags"
                        :key="tab_index"
                      >
                        {{ tag.title }}
                      </li>
                    </ul>
                  </div>
                  <h3 class="fw-bolder">{{ item.name }}</h3>
                  <p class="mt-2 d-flex align-items-center small">
                    <i
                      class="fas fa-map-marker-alt me-2 align-self-start mt-1"
                    ></i>
                    {{ item.address }}
                    <span class="vr ms-2 mx-1"></span>
                    <small
                      class="fw-bold ms-1 text_copy flex-shrink-0"
                      @click="$copy(item.address)"
                      ><i class="far fa-copy"></i> 복사</small
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
              class="info_box bg-gray-relative-100 zindex-2 position-relative"
            >
              <div class="d-flex lh-sm bg-body p-3">
                <a
                  v-if="item.site_url != ''"
                  :href="item.site_url"
                  class="fw-bold text-center w-100 py-2 rounded-start"
                >
                  <!-- <a
                  v-if="item.site_url != ''"
                  class="fw-bold text-center w-100 py-2 rounded-start"
                  @click="windowOpen(item.site_url)"
                > -->
                  <i class="far fa-home-alt small me-1"></i> 홈페이지
                </a>

                <span
                  class="vr bg-gray-relative-500"
                  v-if="item.tel != ''"
                ></span>
                <a
                  v-if="item.tel != ''"
                  :href="`tel:${item.tel}`"
                  class="fw-bold text-center w-100 py-2 rounded-start"
                >
                  <small><i class="fas fa-phone-alt small me-1"></i></small>
                  전화
                </a>
                <span class="vr bg-gray-relative-500"></span>
                <p
                  class="fw-bold ms-px--1 text-center w-100 py-2 rounded-end"
                  @click="OpenDetail()"
                >
                  <i class="far fa-calendar-check me-1"></i> 결제
                </p>
              </div>
              <!-- <div class="bg-body mb-2">
                                <h6 class="fw-bold p-3">운영시간</h6>
                                <div class="small px-3">
                                    <ul class="small">
                                        <li v-for="(time,index) in item.times" :key="index" class="pb-3">
                                            <div class="fw-bold mb-1">
                                                <span v-for="(month,month_index) in time.month" :key="month_index" class="me-1">{{month}}월</span>
                                            </div>
                                            <div class="d-flex flex-wrap">
                                                <div class="w-50">
                                                    <span class="text-gray-relative-600 me-2">평일</span>
                                                    <span>{{time.start_time}}~{{time.end_time}}</span>
                                                </div>
                                                <div class="w-50">
                                                    <span class="text-gray-relative-600 me-2">토요일</span>
                                                    <span>{{time.start_time}}~{{time.end_time}}</span>
                                                </div>
                                                <div class="w-50">
                                                    <span class="text-gray-relative-600 me-2">일요일</span>
                                                    <span>{{time.start_time}}~{{time.end_time}}</span>
                                                </div>
                                                <div class="w-50">
                                                    <span class="text-gray-relative-600 me-2">공휴일</span>
                                                    <span>{{time.start_time}}~{{time.end_time}}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->

              <!-- <div class="bg-body">
                                <h6 class="fw-bold p-3">매장사진</h6>
                                <VueSlickCarousel v-bind="settings" class="shop_banner">
                                    <div class="bg-light border-0" v-for="(item,index) in item.imgs" :key="index">
                                        <div class="bg-img" :style="`background-image: url(${item})`"></div>
                                    </div>
                                </VueSlickCarousel>
                            </div> -->
              <!-- <div class="pt-2 pb-4 px-3 small">
                                <small class="text-gray-relative-500">*정확한 정보는 해당 매장에 직접 문의하시기 바랍니다.</small>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReservationPopup ref="ReservationPopup" />
  </div>
</template>

<script>
// import VueSlickCarousel from 'vue-slick-carousel'
import ReservationPopup from '@/components/popup/Reservation.vue';
const CryptoJS = require('crypto-js');
export default {
  components: {
    // VueSlickCarousel,
    ReservationPopup,
  },
  data() {
    return {
      item: {},
      pp: false,
      off: false,
      timeout_id: null,
      login: this.$store.state.login,
    };
  },
  methods: {
    OpenDetail() {
      const login = this.login;

      if (login == true) {
        this.$refs.ReservationPopup.item = this.item;
        this.$refs.ReservationPopup.pp = true;
      } else {
        this.$router.push({ path: '/signin' });
      }
    },
    Bookmark() {
      if (this.login == true) {
        const code = this.item.code;
        const bookmark = this.item.bookmark;
        const body = { code, bookmark };
        const req = CryptoJS.AES.encrypt(
          JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN
        ).toString();
        this.item.bookmark = !this.item.bookmark;
        this.$http
          .post('/front/search/ChangeFavorites', { req })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(() => {
                  this.$router.push({ path: '/signin' });
                });
              }
            }
          });
      } else {
        this.$router.push({ path: '/signin' });
      }
    },

    // front ui
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
    windowOpen(url) {
      window.open(url, 'popup');
    },
  },
};
</script>

<style lang="scss" scoped>
.black_bg {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.text_copy {
  color: rgb(40, 193, 249);
}
.disabled {
  user-select: none;
}
</style>
<style lang="scss">
// .shop_banner{
//     .slick-slide{
//         .bg-img{
//             width: 100vw;
//             height: 200px;
//         }
//     }
//     .slick-prev, .slick-next{
//         z-index: 1;
//         background-color: rgba($color: #000000, $alpha: 0.3);
//         width: 30px;
//         height: 30px;
//         &:before{
//             font-family: 'Font Awesome 5 Pro';
//             font-weight: 300;
//         }
//     }
//     .slick-prev{
//         left: 0;

//         &:before{
//             content: "\f053";
//         }
//     }
//     .slick-next{
//         right: 0;

//         &:before{
//             content: "\f054";
//         }
//     }
// }
</style>
